<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  created() {
    // 判断cookies中是否有token 有token则存入vuex
    let token = this.getCookie('token');
    let refreshToken = this.getCookie('refresh_token');
    let userInfo = this.getCookie('user_info');
    this.$store.commit('setToken', token);
    this.$store.commit('setRefreshToken', refreshToken);
    this.$store.commit('setUserInfo', userInfo ? JSON.parse(userInfo) : null);
  },
  methods: {
    //读取cookie
    getCookie(cName){
      let strCookie = document.cookie;//获取cookie字符串
      let arrCookies = strCookie.split(";");//分割
      //遍历匹配
      for (let i = 0; i < arrCookies.length; i++) {
        let arr = arrCookies[i].split("=");
        if (arr[0].trim() === cName){
            return arr[1].trim();
        }
      }
      return "";
    }
  }
}
</script>

<style lang="less">

</style>
