import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import getters from './getters'

Vue.use(Vuex);

const state = {
  config: {
    baseURL: process.env.NODE_ENV === 'development' ? "http://192.168.30.26:8080" : 'https://api.qiyi183.com'
  },
  token: '',
  refresh_token: '',
  user_info: null,
};

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions: {},
  modules: {}
})
