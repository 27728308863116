import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as filters from '@utils/filters'
import * as utils from '@utils/utils'
import '@assets/less/public.less'
import moment from 'moment'
import Cookies from 'js-cookie'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@assets/font/iconfont.css';
import '@assets/font/iconfont.js';
import * as echarts from 'echarts';
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

Vue.config.productionTip = false;

//注册全局过滤器
Object.keys(filters).forEach(key => Vue.filter(key, filters[key]));

// 注册全局方法
Vue.use({install: (Vue) => Vue.prototype['$echarts'] = echarts});
Vue.use({install: (Vue) => Vue.prototype['$Cookies'] = Cookies});
Vue.use({install: (Vue) => Vue.prototype['$moment'] = moment});

Vue.use({install: (Vue, opt) => Object.keys(utils).forEach(key => Vue.prototype[`$${key}`] = utils[key])});

Vue.use(ElementUI);
Vue.use(mavonEditor);

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
