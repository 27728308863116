const Home = () => import('@page/Home');
// 首页
const Index = () => import('@page/index/Index'); // 首页
// 博客
const Article = () => import('@page/blog/Article'); // 文章
const Comment = () => import('@page/blog/Comment'); // 评论
const Column = () => import('@page/blog/Column'); // 专栏
const Music = () => import('@page/blog/Music'); // 标签
const Tag = () => import('@page/blog/Tag'); // 标签
// 小说
const Author = () => import('@page/novel/Author'); // 作者
const Category = () => import('@page/novel/Category'); // 分类
const Book = () => import('@page/novel/Book'); // 书籍
const Chapter = () => import('@page/novel/Chapter'); // 章节
const Collect = () => import('@page/novel/Collect'); // 章节
const Keyword = () => import('@page/novel/Keyword'); // Keyword
// 系统
const Mine = () => import('@page/system/Mine');
const Admin = () => import('@page/system/Admin'); // Admin
const Role = () => import('@page/system/Role'); // Role
const Department = () => import('@page/system/Department'); // 章节
const Profile = () => import('@page/system/Profile'); // 配置
const ProfileValue = () => import('@page/system/ProfileValue'); // 配置值
const Decision = () => import('@page/system/Decision'); // 决策
// 用户
const User = () => import('@page/user/User');
// 应用
const Advertise = () => import('@page/app/Advertise');
const Special = () => import('@page/app/Special');
// 登录
const Login = () => import('@page/login/Login'); // 登录

export default [
  {
    path: '*',
    redirect: '/index',
    component: Home
  },
  {
    path: '/',
    name: 'Home', 
    component: Home,
    redirect: '/index',
    children: [
      {path: '/index', name: 'Index', component: Index},
      // 博客
      {path: '/article', name: 'Article', component: Article},
      {path: '/comment', name: 'Comment', component: Comment},
      {path: '/column', name: 'Column', component: Column},
      {path: '/music', name: 'Music', component: Music},
      {path: '/tag', name: 'Tag', component: Tag},
      // 小说
      {path: '/author', name: 'Author', component: Author},
      {path: '/category', name: 'Category', component: Category},
      {path: '/book', name: 'Book', component: Book},
      {path: '/chapter', name: 'Chapter', component: Chapter},
      {path: '/collect', name: 'Collect', component: Collect},
      {path: '/keyword', name: 'Keyword', component: Keyword},
      // 用户
      {path: '/user', name: 'User', component: User},
      // 应用
      {path: '/advertise', name: 'Advertise', component: Advertise},
      {path: '/special', name: 'Special', component: Special},
      // 系统
      {path: '/admin', name: 'Admin', component: Admin},
      {path: '/role', name: 'Role', component: Role},
      {path: '/department', name: 'Department', component: Department},
      {path: '/profile', name: 'Profile', component: Profile},
      {path: '/profile/:id', name: 'ProfileValue', component: ProfileValue},
      {path: '/decision', name: 'Decision', component: Decision},
      // 我的
      {path: '/mine', name: 'Mine', component: Mine},
    ]
  },
  {path: '/login', name: 'Login', component: Login}
]