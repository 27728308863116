export const removeItem = (arr, val) => { // 删除数组中的元素
  // let index = arr.indexOf(val);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export const removeItems = (arr, vals) => {
  vals.forEach((val) => {
    let index = arr.indexOf(val);
    if (index > -1) {
      arr.splice(index, 1);
    }
  });
  return arr;
};

export const clone = (obj) => {
    return Object.assign({}, obj);
};

//设置cookie
export const setCookie = (cName, cValue, expireTimestamp) => {
  let exDate = new Date();//获取时间
  let timestamp = exDate.getTime();
  if (!expireTimestamp) {
    timestamp += 2 * 3600 * 1000;
  } else {
    timestamp += expireTimestamp * 1000;
  }
  exDate.setTime(timestamp);//保存的天数
  window.document.cookie=cName + "=" + cValue + ";expires="+exDate.toGMTString();
};

//读取cookie
export const getCookie = (cName) => {
  let strCookie = document.cookie;//获取cookie字符串
  let arrCookies = strCookie.split("; ");//分割
  //遍历匹配
  for (let i = 0; i < arrCookies.length; i++) {
      let arr = arrCookies[i].split("=");
      if (arr[0].trim() === cName){
          return arr[1];
      }
  }
  return "";
};

export const delCookies = (...cNames) => {
  console.log(cNames);
    let strCookie = document.cookie;//获取cookie字符串
    let arrCookies = strCookie.split("; ");//分割
    //遍历匹配
    for (let i = 0; i < arrCookies.length; i++) {
        let arr = arrCookies[i].split("=");
        console.log(arr[0].trim());
        console.log(cNames.indexOf(arr[0].trim()));
        if (cNames.indexOf(arr[0].trim()) > -1){
            setCookie(arr[0].trim(), '', -1);
        }
    }
    return "";
}

//清除cookie
export const clearCookie = () => {
  this.setCookie("", "" , -1);//修改2值都为空，天数为负1天就好了
};