export const getSex = num => { // 判断性别
  if(num === 0) return '未知';
  if(num === 1) return '男';
  if(num === 2) return '女';
};

export const isBirth = val => { // 展示生日日期
  if(val !== 'Invalid date') return val;
  return '';
};

export const formatDate = (timestamp, fmt) => {
    let dateTime = new Date(timestamp * 1000);
    let o = {
        'M+': dateTime.getMonth() + 1,
        'd+': dateTime.getDate(),
        'h+': dateTime.getHours(),
        'm+': dateTime.getMinutes(),
        's+': dateTime.getSeconds(),
        'q+': Math.floor((dateTime.getMonth() + 3) / 3),
        'S': dateTime.getMilliseconds(),
        'APM': dateTime.getHours() < 12 ? '上午': '下午',
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (dateTime.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (let k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
        }
    }
    return fmt;
};