export default {
  setToken(state, token='') {
    state.token = token;
  },
  setRefreshToken(state, token='') {
    state.refresh_token = token;
  },
  setUserInfo(state, data) {
    state.user_info = data;
  },
}