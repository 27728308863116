export default {
    getToken(state) {
        return state.token
    },
    getRefreshToken(state) {
        return state.refresh_token;
    },
    getConfig(state) {
        return state.config;
    },
    userInfo(state) {
        return state.user_info;
    }
}